import { ref, Ref } from '@vue/composition-api'
import repositories from '@/repositories'
import { parentProfile } from './profile'
import { useQuestionnaire } from './questionnaire'

export const usePrevTreatments = () => {
  const intolerances: Ref<any[]> = ref([])
  const loadingPrevTreatments = ref(false)
  const submittingPrevTreatments = ref(false)

  const { getCompletedSections } = useQuestionnaire()

  const getPrevTreatments = async () => {
    loadingPrevTreatments.value = true
    try {
      intolerances.value = [...(await repositories.intolerances
        .getIntolerances()).data, {
        intoleranceid: 0,
        intolerance: 'Other'
      }
      ].map(({ intolerance, ...rest }) => ({
        ...rest,
        intolerance: intolerance.replace('\\\'', '\''),
        value: false
      }))
    } catch (err) {} finally {
      loadingPrevTreatments.value = false
    }
  }

  const submitPrevTreatments = async ({
    hadSleepStudy,
    sleepStudyLoc,
    sleepStudyDate,

    hadCPAP,
    currentCPAP,
    cpapNight,
    cpapHour,
    otherIntolerance,

    curDentalDevice,
    prevDentalDevice,
    fabByDentist,
    dentistName,
    otc,
    experience,

    hadSurgery,
    surgeries,
    otherTherapy
  }: any) => {
    submittingPrevTreatments.value = true
    const {
      patientid,
      userid,
      docid,
      sleepStatus,
      symptomsStatus,
      historyStatus,
      lastTreatment: {
        qPage2Id
      }
    } = parentProfile.value

    try {
      if (hadSurgery) {
        await Promise.all(surgeries
          .map(async ({ surgery, surgeon, date }: any) => {
            await repositories.previousSurgeries.submitPrevSurgeries({
              patientid,
              date,
              surgery,
              surgeon
            })
          }))
      }

      await repositories.previousTreatments.submitPrevTreatments({
        qPage2Id,
        info: {
          patientid,
          userid,
          docid,
          hadSleepStudy,
          sleepStudyLoc,
          sleepStudyDate,
          hadCPAP,
          intolerances: intolerances.value,
          otherIntolerance,
          otherTherapy,
          curDentalDevice,
          prevDentalDevice,
          otc,
          fabByDentist,
          dentistName,
          experience,
          currentCPAP,
          cpapNight,
          cpapHour,
          hadSurgery
        }
      })
      const havePreMedication = parentProfile.value.premedcheck
      const preMedication = parentProfile.value.premed
      if (sleepStatus >= 1 && symptomsStatus >= 1 && historyStatus >= 1) {
        parentProfile.value = (await repositories.profile
          .updatePatientQuestionnaire({
            patientid,
            premedcheck: havePreMedication ? 1 : 0,
            premed: havePreMedication ? preMedication : '',

            symptomsStatus: 2,
            sleepStatus: 2,
            treatmentsStatus: 2,
            historyStatus: 2
          })
        ).data
      } else {
        parentProfile.value = (await repositories.profile
          .updatePatientQuestionnaire({
            patientid,
            premedcheck: havePreMedication ? 1 : 0,
            premed: havePreMedication ? preMedication : '',

            symptomsStatus: symptomsStatus,
            sleepStatus: sleepStatus,
            treatmentsStatus: 1,
            historyStatus: historyStatus
          })
        ).data
      }

      await getCompletedSections()
    } catch (err) {} finally {
      submittingPrevTreatments.value = false
    }
  }

  return {
    intolerances,

    loadingPrevTreatments,
    getPrevTreatments,

    submittingPrevTreatments,
    submitPrevTreatments
  }
}
