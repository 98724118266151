<template>
  <common-form
    #default="{ isValidForm }"
    @submit="submitData"
  >
    <pp-frame>
      <common-loading
        :loading="loadingCompletedSections || loadingPrevTreatments"
      >
        <v-alert
          v-if="qSections && qSections.prevTreatments"
          type="success"
        >
          {{ $t('This page has been completed.') }}<br>
          {{ translate(`
          If you need to make changes to this page,
          please contact DOCTOR at somewhere!
          `) }}
        </v-alert>
        <template v-else>
          <h2 class="blue-grey--text">
            {{ $t('Sleep Study') }}
          </h2>
          <div>
            {{ $t('Have you had a sleep study?') }}
            <common-toggle
              v-model="hadSleepStudy"
              class="ml-3"
            />
          </div>
          <v-row v-if="hadSleepStudy">
            <v-col cols="6">
              <v-text-field
                v-model="sleepStudyLoc"
                :label="hadSleepStudyLabel"
              />
            </v-col>
            <v-col cols="6">
              <vuetify-date-picker
                v-model="sleepStudyDate"
                :label="datepickerLabel"
                :rules="[
                  $validators.isValid(),
                  $validators.isValidDate()
                ]"
              />
            </v-col>
          </v-row>

          <h2 class="blue-grey--text mt-6">
            {{ $t('CPAP Intolerance') }}
          </h2>
          <div>
            {{ $t('Have you tried CPAP?') }}
            <common-toggle
              v-model="hadCPAP"
              class="ml-3"
            />
          </div>
          <div
            v-if="hadCPAP"
            class="mt-2"
          >
            {{ $t('Are you currently using CPAP?') }}
            <common-toggle
              v-model="currentCPAP"
              class="ml-3"
            />
          </div>
          <v-row v-if="currentCPAP">
            <v-col
              cols="12"
              sm="6"
            >
              <pp-select
                v-model="cpapNight"
                :title="cpapNightLabel"
                :min="0"
                :max="7"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <pp-select
                v-model="cpapHour"
                :title="cpapNightLabel"
                :min="0"
                :max="24"
              />
            </v-col>
          </v-row>
          <v-subheader
            v-if="hadCPAP"
            class="font-weight-bold"
          >
            {{ $t('What are your chief complaints about CPAP?') }}
          </v-subheader>
          <v-row v-if="hadCPAP">
            <v-col
              v-for="(intolerance, idx) in intolerances"
              :key="`intolerance-${idx}`"
              class="my-0 py-0 d-flex align-stretch"
              :order="$vuetify.breakpoint.smAndDown && (idx % 2)
                ? 'last' : 'first'"
              cols="12"
              md="6"
            >
              <v-checkbox
                v-model="intolerance.value"
                class="ma-0"
                :label="intolerance.intolerance"
                hide-details
              />
            </v-col>
          </v-row>
          <v-textarea
            v-if="hadCPAP && haveOtherIntolerence"
            v-model="otherIntolerance"
            :label="experienceLabel"
            class="mt-5"
            rows="4"
            outlined
          />

          <h2 class="blue-grey--text mt-6">
            {{ $t('Dental Devices') }}
          </h2>
          <div>
            {{ translate(`
            Are you currently wearing a dental device
            specifically designed to treat sleep apnea?
            `) }}
            <common-toggle
              v-model="curDentalDevice"
              class="ml-3"
            />
          </div>
          <div>
            {{ translate(`
            Have you previously tried a dental device for sleep apnea treatment?
            `) }}
            <common-toggle
              v-model="prevDentalDevice"
              class="ml-3"
            />
          </div>
          <v-row
            v-if="curDentalDevice || prevDentalDevice"
            no-gutters
          >
            <v-col
              cols="12"
              :sm="fabByDentist ? 6 : 12"
            >
              <v-checkbox
                v-model="fabByDentist"
                class="ma-0"
                :label="fabByDentistLabel"
                hide-details
              />
            </v-col>
            <v-col
              v-if="fabByDentist"
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="dentistName"
                :label="dentistNameLabel"
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="otc"
                class="ma-0"
                :label="otcLabel"
                hide-details
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="experience"
                :label="experienceLabel"
                class="mt-5"
                rows="4"
                outlined
              />
            </v-col>
          </v-row>

          <h2 class="blue-grey--text mt-6">
            {{ $t('Surgery') }}
          </h2>
          <div>
            {{ $t('Have you had surgery for snoring or sleep apnea?') }}
            <common-toggle
              v-model="hadSurgery"
              class="ml-3"
            />
          </div>
          <v-subheader v-if="hadSurgery">
            {{ translate(`
            Please list any nose, palatal, throat, tongue,
            or jaw surgeries you have had.
            (each is individual text field in SW)
            `) }}
          </v-subheader>
          <v-list
            v-if="hadSurgery"
            dense
            class="no-background"
          >
            <v-list-item
              v-for="(surgery, idx) in surgeries"
              :key="`surgery-${idx}`"
            >
              <v-list-item-title class="overflow-visible">
                <v-row>
                  <v-col
                    class="py-0"
                    cols="4"
                  >
                    <vuetify-date-picker
                      v-model="surgery.date"
                      :label="datepickerLabel"
                      :rules="[
                        $validators.isValid(),
                        $validators.isValidDate()
                      ]"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="4"
                  >
                    <v-text-field
                      v-model="surgery.surgeon"
                      :label="surgeonNameLabel"
                    />
                  </v-col>
                  <v-col
                    class="py-0"
                    cols="4"
                  >
                    <v-text-field
                      v-model="surgery.surgery"
                      :label="surgeryNameLabel"
                    />
                  </v-col>
                </v-row>
              </v-list-item-title>
              <v-list-item-action>
                <v-btn
                  icon
                  @click="removeSurgery(idx)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-btn
            v-if="hadSurgery"
            color="primary"
            class="mx-auto"
            @click="addSurgery"
          >
            {{ $t('Add Surgery') }}
          </v-btn>
          <v-textarea
            v-model="otherTherapy"
            :label="otherTherapyLabel"
            class="mt-5"
            outlined
            hide-details=""
          />
        </template>
      </common-loading>

      <template #actions>
        <v-btn to="/questionnaire/epworth">
          <v-icon>mdi-chevron-double-left</v-icon>
          {{ $t('Back') }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="primary"
          type="submit"
          :disabled="!isValidForm"
          :loading="loadingPrevTreatments ||
            submittingPrevTreatments ||
            loadingCompletedSections"
        >
          {{ qSections && qSections.prevTreatments
            ? $t('Next') : $t('Save and Proceed') }}
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>
      </template>
    </pp-frame>
  </common-form>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { utils } from 'ui-common'
import { useQuestionnaire } from '@/use/questionnaire'
import { usePrevTreatments } from '@/use/prev-treatments'

export default {
  setup: (_, { root }) => {
    const {
      qSections,
      loadingCompletedSections
    } = useQuestionnaire()

    const {
      intolerances,
      loadingPrevTreatments,
      submittingPrevTreatments,
      getPrevTreatments,
      submitPrevTreatments
    } = usePrevTreatments()

    const hadSleepStudy = ref(false)
    const sleepStudyLoc = ref('')
    const sleepStudyDate = ref(null)

    const hadCPAP = ref(false)
    const currentCPAP = ref(false)
    const cpapNight = ref(0)
    const cpapHour = ref(0)
    const otherIntolerance = ref('')

    const curDentalDevice = ref(false)
    const prevDentalDevice = ref(false)
    const fabByDentist = ref(false)
    const dentistName = ref('')
    const otc = ref(false)
    const experience = ref('')

    const hadSurgery = ref(false)
    const surgeries = ref([{
      date: null,
      surgeon: '',
      surgery: ''
    }])
    const otherTherapy = ref('')

    const haveOtherIntolerence = computed(() =>
      intolerances.value.length &&
      intolerances.value.filter(({ intoleranceid }) => !intoleranceid)[0].value)

    const addSurgery = () => {
      surgeries.value.push({
        date: null,
        surgeon: '',
        surgery: ''
      })
    }

    const removeSurgery = (id) => {
      surgeries.value.splice(id, 1)
    }

    const submitData = async () => {
      if (qSections.value.prevTreatments) {
        utils.navigate('/questionnaire/history')
        return
      }

      try {
        await submitPrevTreatments({
          hadSleepStudy: hadSleepStudy.value,
          sleepStudyLoc: sleepStudyLoc.value,
          sleepStudyDate: sleepStudyDate.value,

          hadCPAP: hadCPAP.value,
          currentCPAP: currentCPAP.value,
          cpapNight: cpapNight.value,
          cpapHour: cpapHour.value,
          otherIntolerance: otherIntolerance.value,

          curDentalDevice: curDentalDevice.value,
          prevDentalDevice: prevDentalDevice.value,
          fabByDentist: fabByDentist.value,
          dentistName: dentistName.value,
          otc: otc.value,
          experience: experience.value,

          hadSurgery: hadSurgery.value,
          surgeries: surgeries.value,
          otherTherapy: otherTherapy.value
        })
        utils.navigate('/questionnaire/history')
      } catch (err) {}
    }

    onMounted(getPrevTreatments)

    return {
      qSections,
      loadingCompletedSections,

      loadingPrevTreatments,
      submittingPrevTreatments,

      intolerances,

      hadSleepStudy,
      sleepStudyLoc,
      sleepStudyDate,
      hadCPAP,
      currentCPAP,
      cpapNight,
      cpapHour,
      otherIntolerance,

      curDentalDevice,
      prevDentalDevice,
      fabByDentist,
      dentistName,
      otc,
      experience,

      hadSurgery,
      surgeries,
      otherTherapy,

      haveOtherIntolerence,

      addSurgery,
      removeSurgery,

      submitData,

      hadSleepStudyLabel: computed(() =>
        root.$i18n.t('If yes, where?')
      ),
      datepickerLabel: computed(() =>
        root.$i18n.t('Date')
      ),
      cpapNightLabel: computed(() =>
        root.$i18n.t('How many hours each night do you wear it?')
      ),
      experienceLabel: computed(() =>
        root.$i18n.t('Describe your experience')
      ),
      fabByDentistLabel: computed(() =>
        root.$i18n.t('Was it fabricated by a dentist?')
      ),
      dentistNameLabel: computed(() =>
        root.$i18n.t('Who?')
      ),
      otcLabel: computed(() =>
        root.$i18n.t('Was it over-the-counter (OTC)?')
      ),
      surgeonNameLabel: computed(() =>
        root.$i18n.t('Surgeon')
      ),
      surgeryNameLabel: computed(() =>
        root.$i18n.t('Surgery')
      ),
      otherTherapyLabel: computed(() =>
        root.$i18n.t(
          'Other therapy attempts and how each impacted your ' +
          'snoring and apnea and sleep quality.'
        )
      )
    }
  }
}
</script>
